function setDataIndex() {
  // check if visual section, slider or slide form exists
  const visualSectionForm = document.getElementById('visual-section-form');
  const sliderForm = document.getElementById('slider-form');
  const keyForm = document.getElementById('key-form');

  if (visualSectionForm || sliderForm || keyForm){
    // get lists of  file inputs & .valid-message & .invalid-message elements
    // all those lists have the same length, so we can use same index
    // to update data-index attribute
    const fileInputs = document.querySelectorAll('#image-fields [data-index]');

    const validMessages = document.querySelectorAll('.valid-message');
    const invalidMessages = document.querySelectorAll('.invalid-message');

    for (let index = 0; index < fileInputs.length; index++) {
      fileInputs[index].setAttribute('data-index', index)
      validMessages[index].setAttribute('data-index', index)
      invalidMessages[index].setAttribute('data-index', index)
    }
  }
}


document.addEventListener('turbolinks:load', setDataIndex);
document.addEventListener('ajax:success', setDataIndex)

// $(document).on('cocoon:after-insert', '#images', function(e) {
//   console.log('hello images');
//   setDataIndex()
// })

$(document).on('cocoon:after-insert', function(e) {
  setDataIndex()
})
