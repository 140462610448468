import React from "react";
import PropTypes from "prop-types";
import Loading from "./Loading";
import CommentList from "./CommentList";
import NewComment from "./NewComment";
import CommentContext from "./CommentContext";
import Dialog from "./Dialog";
import getToken from '../app/getToken';

const HEADERS = {
  'X-CSRF-Token': getToken(),
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

class CommentsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      comments: [],
      newComment: true,
      body: '',
      commenter: '',
      editing: null,
      errors: null,
      showComments: false,
      dialogVisible: false,
      dialog: '',
    };
    this.handleAddComment = this.handleAddComment.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancelEditing = this.handleCancelEditing.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    // this.handleDisableComments = this.handleDisableComments.bind(this);
    this.clearFlash = this.clearFlash.bind(this);
    this.showDialog = this.showDialog.bind(this);
  }


  componentDidMount() {
    this.fetchData();
  }


  // show the dialog, then start countdown to hide again
  showDialog(message){
    this.setState({ dialogVisible:true, dialog: message }, () => {
      setTimeout(() => this.setState({ dialogVisible:false }), 3000)
    })
  }


  // Get those lovely comments!
  fetchData(){
    fetch(this.props.url)
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ errors: res.error })
        else this.setState({ loading: false, comments: res.data })
      })
  }


  // Show/Hide NewComment form and reset body
  handleAddComment(){
    this.setState({ newComment: true, body: '', comment: '', editing: null })
  }


  // Update state based on Form input
  handleChange(e){
    const newState = this.state;
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }


  // Submit CREATE and EDIT requests, then update Comment list
  handleSubmit(e){
    e.preventDefault();

    if(this.state.body){
      let url = this.props.url;
      const userID = this.props.user ? this.props.user.id : null

      fetch(url, {
        method: 'POST',
        headers: HEADERS,
        body: JSON.stringify({ comment: { body: this.state.body, user_id:  userID} })
      })
        .then(res => res.json())
        .then(res => {
          if(!res.success) this.setState({ error: res.error })
          else {
            this.setState({ body: '', commenter: ''}, () => {
              this.showDialog('Thanks! Feel free to leave another');
              this.fetchData();
            });

          }
        })
        .catch(e => { this.setState({ error:e }) })
    } else {
      this.showDialog("You haven't written anything yet.");
      const input = document.getElementById('comment_body_input');
      if(input) input.focus();
    }

  }


  // Submit DELETE requests, update Comments
  handleDelete(comment){
    fetch(`${this.props.url}/${comment.id}`, {
      method: 'DELETE',
      headers: HEADERS
    })
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ error: res.error })
        else {
          this.setState({ flash: 'Deleted!' }, this.clearFlash);
          this.fetchData();
        }
      })
      .catch(e => { this.setState({ error:e }) })
  }


  handleEdit(comment){
    this.setState({ editing:comment.id, body:comment.body  })
  }


  handleCancelEditing(e){
    e.preventDefault();
    this.setState({ editing:null, body:'', commenter:'' });
  }

  // UPDATE Commentable Record to disable comments, then hide all output
  // handleDisableComments(){
  //   let url = this.props.url.slice(0, this.props.url.search('comments'));
  //   const body = {};
  //   body[this.props.type] = { allows_comments: false };
  //
  //   fetch(url, {
  //     method: 'PATCH',
  //     headers: HEADERS,
  //     body: JSON.stringify(body)
  //   })
  //     .then(res => res.json())
  //     .then(res => {
  //       if(!res.success) this.setState({ errors: res.error })
  //       // else window.location.reload(); // refresh page
  //       this.setState({ disabled: true })
  //     })
  //     .catch(e => this.setState({ errors:e }))
  // }


  // TODO
  // Hide a flash, BUT I HAVENT'T MADE A FLASH YET
  clearFlash(){
    console.log('clear!');
  }


  render () {

    const {
      comments,
      loading,
      newComment,
      body,
      commenter,
      showComments,
      dialogVisible,
      dialog,
      // editing,
      // disabled,
    } = this.state;

    const {
      commentable,
      url,
      type,
      user,
      showCommenterField,
      showAllComments
    } = this.props;

    // Extract root domain by slicing before 'type',
    // e.g 'item' from 'www.placethatweknow.org/items/1-an-item'
    const domain = url.slice(0, url.search(type));

    if(loading) return <Loading />

    // Render nothing if Comments are disabled for Commentable record
    // if(disabled) return null;

    const context = {
      context: {
        commentable:commentable,
        user:user,
        body:body,
        commenter:commenter,
        handleChange: this.handleChange,
        // handleCancelEditing: this.handleCancelEditing,
        handleSubmit: this.handleSubmit,
        handleEdit: this.handleEdit,
        handleDelete: this.handleDelete,
        // editing: editing,
        domain: domain
      }
    }

    let header = '0 Comments';
    if(comments.length > 0){
      header = `${comments.length} Comment${comments.length > 1 ? 's' : ''}`;
    }


    return (
      <CommentContext.Provider
        value={context}
      >
        <section
          id='comments-container'
        >


          {/* COMMENT FORM */}
          {this.props.active && newComment &&
            <NewComment
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              cancel={() => this.setState({ newComment:false, body:'', commenter:'' })}
              body={body}
              commenter={commenter}
              showCommenterField={showCommenterField}
            />
          }


          {showAllComments && comments.length > 0 &&

            <div
              className='text-center'
            >
              <button
                className="button secondary-bg small-grow round-border"
                onClick={()=>this.setState({showComments:!showComments})}
              >
                <h4 className='nm'>
                  {`${showComments ? 'Hide' : 'Show'} Comments`}
                </h4>
              </button>
            </div>

          }

          {showComments &&
            <React.Fragment>
              <label className="italic small-font sml">
                {header}
              </label>

              <CommentList
                comments={comments}
              />
            </React.Fragment>
          }

        </section>

        <Dialog
          visible={dialogVisible}
          message={dialog}
        />

      </CommentContext.Provider>
    )
  }
}

export default CommentsContainer

CommentsContainer.propTypes = {
  active: PropTypes.bool,
  commentable: PropTypes.object,
  user: PropTypes.object,
  url: PropTypes.string
}
CommentsContainer.defaultProps = {
  active: true,
  showCommenterField: true,
  showAllComments: true
}
