// ===
// Change address label to "Please enter the first line of your organisations address" if "stakeholder" option chosen in user signup form
// ===

// after page load
// get user_type select element
// on change, check selected value
// setup up address input label appropriately

import * as vis from './visibility';

document.addEventListener("turbolinks:load", function(){
  const userTypeEl = document.getElementById('user_type');

  if (userTypeEl){
    userTypeEl.addEventListener('change', (e) => {

      const label = document.getElementById('user_address').previousElementSibling;
      const organisation = document.getElementById('organisation-field');

      if (e.target.value == 'stakeholder') {
        label.textContent = "Please enter the first line of your organisation's address";
        vis.show(organisation);
      } else {
        label.textContent = "Please enter the first line of your address";
        vis.hide(organisation);

      }
    })
  }

})
