// SCOOP FORM ///////////////////////////////////////////////////////
// Helpers for dealing with Rails forms


// insert ID into form route
// Add hidden patch method field to Form
export const configureFormForEditing = (form, id) => {
  // Create method field for PATCH
  const patchField = document.createElement('input');
  patchField.setAttribute('type', 'hidden');
  patchField.setAttribute('name', '_method');
  patchField.setAttribute('value', 'patch');
  // Insert at start of form
  form.insertAdjacentElement('afterbegin', patchField);

  form.action += `/${id}`
}


// Remove Patch method field and ID
export const resetPatchForm = (form) => {
  removeMethodFields();
  removeIdFromForm();
}

export const removeMethodFields = (form) => {
  [...form.elements].forEach(el => {
    if(el.name === '_method'){
      el.remove();
    }
  })
}


// Model name should use underscore _ not hyphen -
export const removeIdFromForm = (form, model) => {
  // Remove id field
  [...form.elements].forEach(el => {
    if(el.name === `${model}[id]`){
      el.remove();
    }
  })

  // Reverse String, remove the ID value, unreverse
  const reversedAction = form.action.split('').reverse().join('');
  const slicePos = reversedAction.indexOf('/') + 1;
  const newAction = reversedAction.slice(slicePos).split('').reverse().join('');

  form.action = newAction;
}



export const resetInputFields = (form) => {
  [...form.elements].forEach(el => {
    if(el.nodeName === 'INPUT' && el.type !== 'submit' && el.name !== 'authenticity_token'){
      el.value = '';
    }
  })
}



// Accept an object and attach key values to form fields
// Any keys without corresponding fields in form will be added
// as hidden fields
export const populateFormFromObject = (form, object, model) => {
  const modelName = model.replace('-', '_'); // Format for Rails
  for (const key in object){
    const field = document.getElementById(`${modelName}_${key}`);
    if(field) field.value = object[key];
    else {
      // create the field and add to the form
      const field = document.createElement('input');
      field.setAttribute('type', 'hidden');
      field.setAttribute('name', `${modelName}[${key}]`);
      field.setAttribute('id', `${modelName}_${key}`);
      field.setAttribute('value', object[key]);
      form.appendChild(field);
    }
  }
}
