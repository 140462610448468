// TODO refactor into DIALOG compoents

import React from 'react';

const TEXT_CLASSES = 'nm white bold blur-bg primary-trans-bg mp round-border text-center'

const Dialog = (props) => (
  <div
    className={`fixed overlay ignore-click toggle-trans ${props.visible ? '' : 'trans'}` }
    style={{top: '40%', left: '25%' }}
  >
    <h3 className={TEXT_CLASSES + ' xlmb'}>
      {props.message}
    </h3>

  </div>
);

export default Dialog;
