// COLORS
// These match with Colors specified in app/assets/stylesheets/_settings.scss

export const orange = '#EDD16D';
export const yellow = '#FFEE97';
export const lightBrown = '#C8A95C';
export const black = '#313A44';
export const darkGreen = '#369D8A';
export const green = '#B7DAA2';
export const gray = '#808080';
export const lightGray = '#EDEAEA';
export const mediumGrayTrans = 'rgba(202, 202, 202, 0.7)';
export const cream = '#F5F6F0';
export const lightBlue = '#99afcc';
export const darkBlue = '#334766';
export const red = '#bb3939';
export const transparent = 'rgba(3, 3, 3, 0)';
export const whiteTrans = 'rgba(255,255,255,0.6)';
export const white = '#FFFFFF'
export const darkAlert = '#752424';

export const primary = darkGreen;
export const secondary = lightBrown;
export const success = green;
export const warning = orange;
export const alert = red;
