import getToken from './getToken';

// GET JSON
export const getJSON = function(url, callback, onError = undefined) {
  var request = new XMLHttpRequest();

  request.open('GET', url, true);

  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      var data = JSON.parse(request.responseText);
      callback(data);
    } else {
      onError(request)
    }
  };

  request.send();
}


// Send FORM DATA
// Defaults to POST
export const sendData = function(data, url, callback, onError, method='POST'){
  var request = new XMLHttpRequest();

  request.open(method, url, true);

  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      // console.log('status is ALL GOOD !!! ');
      var data = JSON.parse(request.responseText);
      callback(data);
    } else {
      console.log('Error: AJAX Request Failed');
      onError()
    }
  };

  request.send(data);
}


// DELETE
export const deleteRequest = function(url, callback, onError = undefined) {
  var request = new XMLHttpRequest();

  const token = getToken();


  request.open('DELETE', url, true);
  request.setRequestHeader('X-CSRF-Token', token)

  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      callback();
    } else {
      console.log('Error: AJAX Request Failed');
      onError(request)
    }
  };

  request.send();
}
