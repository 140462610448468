/**
 * This file will help us to insert the add image button on the posts , when the image is deleted
 * We simply hide the button when image is present and show it when image is deleted
 * 
 */
import * as vis from './visibility';

const addButton = function(){
  // check if the form is for post 
  const postForm = document.getElementById('post-form');
  const eventForm = document.getElementById('event-form');

  
  if (postForm || eventForm) {

    vis.showBySelector('.add-image-link')
  }
}


$(document).on('cocoon:after-remove', function(e) {
  addButton()
})
