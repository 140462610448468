import interact from 'interactjs'


const resizeable = () => {
  const resizables = document.querySelectorAll('.resizeable');
  for(let i = 0; i < resizables.length; i++){
    makeResizeableAndDraggable(resizables[i]);
  }
}

const makeResizeableAndDraggable = element => {
  const target = interact(element)
  // code taken from intaract.js docs
  // https://interactjs.io/docs/resizable
  // https://interactjs.io/docs/draggable


  target
    .resizable({
      edges: { top: true, left: true, bottom: true, right: true },
      listeners: {
        move: function (event) {
          let { x, y } = event.target.dataset

          x = (parseFloat(x) || 0) + event.deltaRect.left
          y = (parseFloat(y) || 0) + event.deltaRect.top

          Object.assign(event.target.style, {
            width: `${event.rect.width}px`,
            height: `${event.rect.height}px`,
            transform: `translate(${x}px, ${y}px)`
          })

          Object.assign(event.target.dataset, { x, y })
        }
      }
    })
    .draggable({
      listeners: {
        move: dragMoveListener
      },
    })
}

function dragMoveListener (event) {
  var target = event.target
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
  var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

  // translate the element
  target.style.webkitTransform =
    target.style.transform =
      'translate(' + x + 'px, ' + y + 'px)'

  // update the position attributes
  target.setAttribute('data-x', x)
  target.setAttribute('data-y', y)
}

document.addEventListener('turbolinks:load', resizeable)
