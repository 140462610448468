// import "core-js/stable";
// import "regenerator-runtime/runtime";

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
import "@oddcamp/cocoon-vanilla-js";
import "chartkick/chart.js"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


require('foundation-sites');
$(document).on('turbolinks:load', function() {
  $(function() { $(document).foundation(); });
});

require("app/analytics.js.erb");
require("app/changeAddressLabel.js");
require("app/toggleAddOptionButton.js");
require("app/direct_uploads");
require("app/highlightSelectBox.js");
require("app/magnifier");
require("app/map");
require("app/openDialog");
require("app/openNoticePopup");
// require("app/promptUserSignup");
require("app/resizableAndDraggable");
require("app/scrollTo");
require("app/setItemNumberInNestedForm");
require("app/setDataIndexForImageInputs");
require("app/showOrHideDateFieldsInConsultationForm");
require("app/stickyConsultationNav")
require("app/submitFormOnChange.js");
require("app/triggerFileAttached");
require("app/updateSectionForm");
require("app/square");
require("app/toggleAddImageButton");
require("app/insertAddImageButtonOnPosts");
require("app/pdfFileInput");
require("trix");
require("@rails/actiontext");
require('juxtaposejs/build/js/juxtapose');


import 'react-app-polyfill/ie9';
import 'core-js/features/string/';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
