
document.addEventListener('turbolinks:load', function(){

  const noticePopup = document.getElementById('notice-popup');

  if (noticePopup){

    $(document).on('ajax:success', function(e) {
      // show notice-open popup
      $('#notice-popup').foundation('open');
    })
  }

});
