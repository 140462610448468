import * as vis from './visibility';

document.addEventListener('turbolinks:load', function(){
  // Add listener to toggle Add Option button to all Question Type inputs
  const qTypeFields = document.querySelectorAll('#question_question_type');

  for(let i = 0; i < qTypeFields.length; i++){
    // Toggle whether the fields is shown on load
    showOrHideAddNewOptionButton(qTypeFields[i]);

    // Toggle whether the fields is shown when the question type is changed
    qTypeFields[i].addEventListener('change', (e) => {
      showOrHideAddNewOptionButton(e.target);
    });
  }

  // Add the listener to new Question Type inputs as they are added by Cocoon
  $(document).on('cocoon:after-insert', function(e) {
    if (e.target.lastElementChild.classList.contains('add-question-link')){
      const input = e.target.previousElementSibling.querySelector(
        '#question_question_type'
      );
      input.addEventListener('change', (e) => {
        showOrHideAddNewOptionButton(e.target)
      })
    }
  })
});

// Show Add Option button unless its' a text or bool question
function showOrHideAddNewOptionButton(input){
  const btn = input.parentElement.parentElement.nextElementSibling;
  if (input.value === 'text' || input.value === 'boolean' ){
    vis.hide(btn);
  } else {
    vis.show(btn);
  }
}



