import React from "react"
import PropTypes from "prop-types"
class Success extends React.Component {
  render () {
    return (
      <div id='success' className="lmt">
        <h2 className="text-center lmb">Thanks a lot!</h2>

        {/*<div className="text-center">
          <a href={this.props.projectPageUrl}>
            <div className="button black-bg">
              <span className="white">Go back to project page</span>
            </div>
          </a>
        </div>*/}
      </div>
    );
  }
}

export default Success
