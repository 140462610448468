import React from "react";

const AGES = ["Under 13", "13-17", "18-24", "25-34", "35-44", "45-64", "65+"];
const GENDERS = ["Male", "Female", "Other", "Prefer not to say"];
const ETHNICITIES = [
  "White",
  "Mixed / Multiple ethnic groups",
  "Asian / Asian British",
  "Black / African / Caribbean / Black British",
  "Other ethnic group",
];

class DemographicQuestions extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const ageOptions = AGES.map((a) => (
      <option key={a} value={a}>
        {a}
      </option>
    ));

    const genderOptions = GENDERS.map((g) => (
      <option key={g} value={g}>
        {g}
      </option>
    ));

    const ethnicityOptions = ETHNICITIES.map((g) => (
      <option key={g} value={g}>
        {g}
      </option>
    ));

    const placeholder = (
      <option key={"placeholder"} disabled value={''} >
        Please Select ...
      </option>
    );

    ageOptions.splice(0, 0, placeholder);
    genderOptions.splice(0, 0, placeholder);
    ethnicityOptions.splice(0, 0, placeholder);

    return (
      <div
        className="grid-x align-center lpt hidden transparent"
        id="demographic-container"
        ref={this.props.demographicRef}
      >
        <section className="cell small-10 medium-8">
          <h2 className="lmb text-center">Thanks!</h2>

          <p className="lmb lead text-center">
            If you're happy to help us understand the views of the community
            better, please tell us about yourself.
          </p>

          <p className="lmb lead text-center">
            Feel free to skip this if you don't want to.
          </p>

          <div className="lmb">
            <div className="field mmb">
              <label htmlFor="age">Age (optional)</label>
              <select
                onChange={this.props.handleChange}
                name="age"
                id="survey_age"
                prompt="Please Select"
                value={this.props.state.age}
                defaultValue={''}
              >
                {ageOptions}
              </select>
            </div>

            <div className="field mmb">
              <label htmlFor="gender">Gender (optional)</label>
              <select
                onChange={this.props.handleChange}
                name="gender"
                id="survey_gender"
                prompt="Please Select"
                value={this.props.state.gender}
                defaultValue={''}
              >
                {genderOptions}
              </select>
            </div>

            <div className="field">
              <label htmlFor="ethnicity">Ethnicity (optional)</label>
              <select
                onChange={this.props.handleChange}
                name="ethnicity"
                id="survey_ethnicity"
                prompt="Please Select"
                value={this.props.state.ethnicity}
                defaultValue={''}
              >
                {ethnicityOptions}
              </select>
            </div>

            <div className="field">
              <label htmlFor="disability">
                Do you consider yourself to have a disability? (optional)
              </label>

              <div className="row">
                <div className="row align-middle mmr">
                  <input
                    className="smr"
                    onChange={this.props.handleChange}
                    type="radio"
                    name={`disability`}
                    id={`disability_true`}
                    value={"Yes"}
                    checked={this.props.state.disability === "Yes"}
                  />
                  <h4 className="italic text-center mmt ">Yes</h4>
                </div>

                <div className="row align-middle">
                  <input
                    className="smr"
                    onChange={this.props.handleChange}
                    type="radio"
                    name={`disability`}
                    id={`disability_false`}
                    value={"No"}
                    checked={this.props.state.disability === "No"}
                  />
                  <h4 className="italic text-center mmt ">No</h4>
                </div>
              </div>
            </div>
          </div>

          {/*  POSTCODE - removed  */}
          {/*<div className="field lmb">
            <label
              htmlFor="postcode"
            >
              Postcode (optional)
            </label>
            <input
              onChange={this.props.handleChange}
              type="text"
              name="postcode"
              id="survey_postcode"
              value={this.props.state.postcode}/>
          </div>*/}

          <div className="field lmb">
            <p className="lead ">
              If you are happy to be contacted about your response to this
              survey, please enter your email or phone number.
            </p>

            <label htmlFor="phone_number">Phone Number (optional)</label>
            <input
              onChange={this.props.handleChange}
              type="text"
              name="phone_number"
              id="survey_phone_number"
              value={this.props.state.phone_number}
            />

            <label htmlFor="email">Email (optional)</label>
            <input
              onChange={this.props.handleChange}
              type="text"
              name="email"
              id="survey_email"
              value={this.props.state.email}
            />
          </div>

          <div className="flex-container align-middle align-justify lmb">
            <input
              className="mmr"
              required
              type="checkbox"
              name="data_confirm"
              checked={this.props.state.data_confirm}
              id="survey_data_confirm"
              onChange={this.props.handleDataConfirmation}
            />

            <label htmlFor="data_confirm" style={{ cursor: "inherit" }}>
              Before continuing, please check the box to confirm you accept our{" "}
              <span className="bold">Data Protection Policy</span>. You can read
              it in full{" "}
              <a
                href={this.props.dataUrl}
                target="_blank"
                className="italic bold"
              >
                here
              </a>
              . If you do not check the box, we will not be able to analyse your
              details as part of this consultation.
            </label>
          </div>

          <button
            className="button blue-bg expanded bold h4 small-grow"
            onClick={this.props.handleDemographicSubmit}
            disabled={!this.props.state.data_confirm}
          >
            <h4
              className={`nm ${this.props.state.data_confirm ? "white" : ""}`}
            >
              Submit
            </h4>
          </button>
        </section>
      </div>
    );
  }
}

export default DemographicQuestions;
