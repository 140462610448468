import React from 'react';
import PropTypes from 'prop-types';

const NewIdea = React.forwardRef((props, ref) => (

  <div>

    <h1 className='lmb npb-small-only text-center'>Add An Idea</h1>

    <p className='lmb npb-small-only text-center'>
      We're not sure what to do with this field yet.
      <br/>
      Add your own idea or vote for somebody elses. Or both!
    </p>

    <textarea
      ref={ref}
      className='smb'
      rows={4}
      name="idea"
      onChange={props.handleChange}
      placeholder='type your idea here...'
      value={props.body}
    />

    <button
      className="button primary-bg smb-small-only round-border"
      onClick={props.handleSubmit}
      disabled={props.disabled}
    >
      <span className='white h4'>submit</span>
    </button>

  </div>

));

export default NewIdea;

NewIdea.propTypes = {
  body: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}
