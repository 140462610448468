import React from "react";
import PropTypes from "prop-types";

class RankQuestion extends React.Component {
  render() {
    return (
      <>
        {this.props.attributes.map((attr, i) => (
          <div
            className="grid-x row mmb-small-only full-width"
            key={`${attr}_key`}
          >
            <label htmlFor={attr} className="cell small-10 spr">
              {this.props.answers[i]}
            </label>
            <input
              className="cell small-2"
              type="number"
              min="1"
              max="4"
              id={attr}
              name={attr}
              value={this.props.state[attr]}
              onChange={this.props.handleChange}
            />
          </div>
        ))}
      </>
    );
  }
}

RankQuestion.propTypes = {
  attributes: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
};
export default RankQuestion;
