import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Idea from './Idea';

// Render List of Idea components or placeholder

export default function IdeaList(props) {

  // Placeholder
  if(props.ideas.length === 0){
    return null;

    // return <div className="callout dark-gray cream-bg text-center lead italic">
    //   No ideas yet.
    // </div>
  }

  const ideas = props.ideas.map((idea, index) => (
    <li
      key={'idea_' + index}
    >
      <Idea
        key={index}
        idea={idea}
        // handleVote={props.handleVote}
      />
    </li>
  ))

  return (
    <Fragment>

      {/* <h1
        className='text-center mmb'
      >
        Cast A Vote
      </h1> */}
      <h1
        className='text-center mmb'
      >
        Ideas
      </h1>

      <p
        className='text-center mmb'
      >
        You can't vote anymore but feel free to browse through our ideas bank!
      </p>

      <ul className="no-style-list nm mp np-small-only">
        {ideas}
      </ul>

    </Fragment>
  )
}

IdeaList.propTypes = {
  ideas: PropTypes.array.isRequired,
  handleVote: PropTypes.func
}
