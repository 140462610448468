const createNextDate = (numberOfWeeks) => {
  let newDate = new Date() // today
  newDate.setDate(newDate.getDate() + numberOfWeeks * 7);

  return newDate;
}

const showOrHideDateFields = () => {

  const publicInput = document.getElementById('consultation_public');
  const startDateFields = document.getElementById('start_date_fields');
  const endDateFields = document.getElementById('end_date_fields');

  const startDateSelectFields = startDateFields.querySelectorAll('select');
  const endDateSelectFields = endDateFields.querySelectorAll('select');

  const today = new Date();

  const NUM_WEEKS = 3;
  const nextDate = createNextDate(NUM_WEEKS);


  if (publicInput.checked) {
    startDateFields.classList.remove('hidden');
    endDateFields.classList.remove('hidden');

    startDateSelectFields.forEach((select, index) => {
      switch (index) {
        case 0:
          select.value = today.getDate()
          break;
        case 1:
          select.value = today.getMonth() + 1; // in js we count months form 0
          break;
        case 2:
          select.value = today.getFullYear()
          break;
        default:
          break;
      }
    })


    endDateSelectFields.forEach((select, index) => {
      switch (index) {
        case 0:
          select.value = nextDate.getDate();
          break;
        case 1:
          select.value = nextDate.getMonth() + 1;
          break;
        case 2:
          select.value = nextDate.getFullYear();
          break;
        default:
          break;
      }
    })

  } else {

    startDateFields.classList.add('hidden');
    endDateFields.classList.add('hidden');

    startDateSelectFields.forEach(select => {
      select.value = ''
    })

    endDateSelectFields.forEach(select => {
      select.value = ''
    })
  }

}


document.addEventListener('turbolinks:load', () => {
  const consultationForm = document.getElementById('consultation-form');

  if (consultationForm){
    const publicInput = document.getElementById('consultation_public');

    showOrHideDateFields();
    publicInput.addEventListener('change', showOrHideDateFields)
  }
});