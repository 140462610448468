import React from 'react';
import PropTypes from 'prop-types';

const NewComment = (props) =>(
  <form action="" className="grid-x align-justify">
    <label
      htmlFor="comment_body_input"
      className="show-for-sr"
    >
      Would you like to leave a comment about the plans?
    </label>

    <textarea
      id={`comment_body_input`}
      className='smb cell'
      type="text"
      name="body"
      onChange={props.handleChange}
      value={props.body}
      placeholder='Write what you think here...'
      rows={1}
    />

    {/*<label
      htmlFor="commenter"
      className='text-center'
    >
      Your Name (optional)
    </label>*/}

    {props.showCommenterField &&
      <input
        className='smb cell'
        type="text"
        id={`comment_commenter_input`}
        name="commenter"
        onChange={props.handleChange}
        value={props.commenter}
        placeholder='Leave your name (optional)...'
      />
    }


    <button
      className="button secondary-bg"
      onClick={props.handleSubmit}
      // disabled={props.body.length === 0}
    >
      <h4 className='black nm'>
        Submit
      </h4>
    </button>


  </form>

);

export default NewComment;

NewComment.propTypes = {
  body: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}
