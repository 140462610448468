document.addEventListener("turbolinks:load", function(){

  const consultationPage = document.querySelector('.consultations')
  var sectionNav = document.getElementById("section-nav");

  if(consultationPage && sectionNav){

    // Get the offset position of the sections container
    var sticky = document.querySelector(".sections-container").offsetTop;

    window.addEventListener('scroll', () => {
      if (window.pageYOffset >= sticky) {
        sectionNav.classList.add("sticky")
      } else {
        sectionNav.classList.remove("sticky");
      }
    })
  }
})
