import React, { Fragment } from "react";
import PropTypes from "prop-types";
import NewIdea from './NewIdea';
import IdeaList from './IdeaList';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCog,
} from "@fortawesome/free-solid-svg-icons"
import getToken from '../app/getToken';
import _ from 'lodash';

const HEADERS = {
  'X-CSRF-Token': getToken(),
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}


class IdeasBank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // This is OK as props won't be updated
      // and state will be gradually emptied
      ideas: [],
      body: '',
      submitting: false,
      submittingMessage: '',
      error: null,
      started: false,
      finished: false,
      loading: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVote = this.handleVote.bind(this);
    // this.sendIdeaRequest = this.sendIdeaRequest.bind(this);
  }


  componentDidMount(){
    this.fetchIdeas(() => {
      this.setState({loading:false})
    });
  }


  // Fetch Ideas for Question
  fetchIdeas(callback=null){
    const url = this.props.url;
    fetch(url)
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ error: res.error })
        else this.setState({ ideas: res.data }, () => {
          if(callback) callback();
        });
      })
      .catch(e => { this.setState({ error:e }) })
  }


  sendIdeaRequest(url, idea, patch=false, callback=null, refreshAfterUpdate=true){
    fetch(url, {
      method: `${patch ? 'PATCH' : 'POST'}`,
      headers: HEADERS,
      body: JSON.stringify({
        idea: idea,
      })
    })
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ error: res.error })
        else {
          setTimeout(
            () => {
              this.setState({
                body: '',
                submitting: false,
              });
              if(refreshAfterUpdate) this.fetchIdeas();
              if(callback) callback();
            },
            500
          )


        }
      })
      .catch(e => { this.setState({ error:e }) })
  }


  // Update state based on Form input
  handleChange(e){
    const newState = this.state;
    newState.body = e.target.value;
    this.setState(newState);
  }


  // Submit POST requests, then update Ideas List
  handleSubmit(e){
    e.preventDefault();
    this.setState({ submitting: true, submittingMessage: 'Saving Idea...' })
    this.sendIdeaRequest(this.props.url, {body: this.state.body})
  }


  handleVote(id, newVoteTally, callback=null){
    this.setState({ submitting: true, submittingMessage: 'Saving...' })
    const url = this.props.url + '/' + id;

    this.sendIdeaRequest(
      url,
      { votes: newVoteTally },
      true,
      () => {
        if(callback) callback();
      },
      // false // don't refresh Ideas
    )
  }


  render () {

    const {
      body,
      submittingMessage,
      submitting,
      error,
      ideas,
      finished,
      loading
    } = this.state;

    return (
      <Fragment>

        <div
          id="ideas-bank"
          className={'grid-x align-center'}
          style={{
            height: '80vh'
          }}
        >

          {/* <div
            className="cell medium-6 lp npt np-small-only mpb-small-only"
            id='new-idea-container'
            style={{
              maxHeight: '80vh'
            }}
          >
            {error &&
              <div>
                <p>Oops! Something went wrong!</p>

                <ul>{
                  error.forEach((e) => {
                    <li key={e}>
                      {e}
                    </li>
                  })
                }</ul>
              </div>
            }

            <NewIdea
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              cancel={() => this.setState({ newIdea: false, body: '' })}
              body={body}
              disabled={body.length === 0}
            />
          </div>

          <div
            className="cell medium-6 lp npt np-small-only spb-small-only"
            style={{
              maxHeight: '80vh',
              overflow: 'auto'
            }}
          >

            {loading &&
              <div className="full-height full-width row align-center-middle">
                <h3>Loading...</h3>
              </div>
            }

            {!loading &&
              <IdeaList
                ideas={ideas}
                handleVote={this.handleVote}
              />
            }


          </div>

        </div>

        <div
          id="submitting-feedback"
          className={`row align-center-middle fixed top left lighter-gray-trans-bg blur-bg toggle-trans ${submitting ? '' : 'trans'}`}
          style={{height: '100vh', width: '100vw'}}
        >
          <div className='text-center'>

            <FontAwesomeIcon
              icon={faCog}
              className='smr xsml lmb'
              spin
              size='3x'
            />

            <h3>{submittingMessage}</h3>

          </div> */}

            {!loading &&
              <IdeaList
                ideas={ideas}
              />
            }
        </div>

      </Fragment>
    );
  }
}

export default IdeasBank
