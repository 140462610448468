import * as vis from './visibility';

document.addEventListener('turbolinks:load', function(){

  // Hide Add Image button after it is clicked and Image fields appear.
  $(document).on('cocoon:after-insert', function(e) {
    if (e.target.lastElementChild.classList.contains('add-image-link')){
      vis.hide(e.target.lastElementChild);
    }
  })

  // Show the Add Image button again after Image field removed
  $(document).on('cocoon:after-remove', function(e) {
    if (e.target.id === 'images' || e.target.id === 'image'){
      vis.show(e.target.lastElementChild.children[0])
    }
  })

  // Show Add Image button again after Image saved
  $(document).on('ajax:success', function(e) {
    vis.show(document.querySelector('.add-image-link'));
  })

});



