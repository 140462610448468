document.addEventListener('turbolinks:load', () => {
  let inputs = document.querySelectorAll('[data-pdf-file-input]');

  for(let i = 0 ; i < inputs.length; i++) {
    let input = inputs[i];
    input.addEventListener('change', (e) => {
      // get the closest parent .field
      let fieldContainer = input.closest('.field');

      // get the sibling p tag with class file-name
      let fileName = fieldContainer.querySelector('.file-name');

      // get the file name
      let name = input.files[0].name;
      fileName.innerText = name;

      // Preview the uploaded file
      let filePreviewContainer = fieldContainer.querySelector('#file-preview');
      console.log(filePreviewContainer);
      let file = input.files[0];
      let iframeElem = document.createElement('iframe');
      iframeElem.setAttribute('width', '100%');
      iframeElem.setAttribute('height', '400px'); // You can adjust the height as needed
      iframeElem.src = URL.createObjectURL(file);

      // Clear any previous previews
      filePreviewContainer.innerHTML = '';
      filePreviewContainer.appendChild(iframeElem);
    });
  }
});
