import React from "react";
import PropTypes from "prop-types";
import { __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } from "react";

class RadioQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otherVisible: false
    }
  }

  render() {


    return (
      <>
        <div className="xlmb xlpb full-width">
          <div
            className="checkboxes grid-x align-justify smb" id="survey-answers-grid"
          >
            {this.props.answers.map((answer, i) => (
              <>
                <div className="cell small-1 radio-button-cell">
                  <div className="radio-button-container relative">

                    <input
                      type="radio"
                      id={`question_${this.props.state.selectedQ + 1}_answer_${i + 1}`}
                      name={this.props.attributes[0]}
                      value={i + 1} // answer should be integer from 1-5
                      onChange={(e) => {
                        this.setState({otherVisible: false}, this.props.handleChange(e))
                      }}
                      className="absolute visually-hidden"
                    />

                    <label
                      htmlFor={`question_${
                        this.props.state.selectedQ + 1
                      }_answer_${i +1}`}
                      className="absolute visually-hidden top left"
                    >
                      {answer}
                    </label>

                    <div className="radio-button text-center"></div>
                  </div>
                </div>
                <div className="cell small-1 column align-center">
                  <div className="line hide-for-small-only"></div>
                </div>
              </>
            ))}
          </div>

          <div className="checkboxes grid-x align-justify">
            {this.props.answers.map((answer) => (
              <div className="cell small-1  radio-button-cell">
                <h4
                  className="vertical-text-for-small vertical-text-for-medium text-center"
                >
                  {answer}
                </h4>
              </div>
            ))}
          </div>

        </div>
        {/* comment for the question */}
        {this.props.haveComments && <textarea
          id={`question_${this.props.state.selectedQ + 1}_comment`}
          className='smb cell small-12 medium-10'
          type="text"
          name={`question_${this.props.state.selectedQ + 1}_comment`}
          onChange={this.props.handleChange}
          value={this.props.state[`question_${this.props.state.selectedQ + 1}_comment`]}
          placeholder='Tell us what you think...'
          rows={1}
        />}

        {this.props.openQuestion && (
          <div
            className="cell medium-7 row mmb-small-only"
            key={`question_${this.props.state.selectedQ}_answer_${
              this.props.answers.length + 1
            }`}
          >
            <input
              type="radio"
              name={this.props.attributes[0]}
              id={`question_other`}
              onChange={() => {
                this.setState({otherVisible: !this.state.otherVisible})
              }
              }

            />
            <label htmlFor={`question_other`}>Other</label>
            {this.state.otherVisible &&
              <input
              className="inline"
              type="text"
              onChange={this.props.handleChange}
              name={this.props.attributes[0]}
              id="other_input_answer"
              value={this.props.state[this.props.attributes[0]]}
            />
            }

          </div>
        )}
      </>
    );
  }
}

RadioQuestion.propTypes = {
  attributes: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  openQuestion: PropTypes.bool,
};
export default RadioQuestion;




// <div
//   className="checkboxes grid-x align-justify smb" id="survey-answers-grid"
// >
//   {this.props.answers.map((answer, i) => (
//     <>
//       <div className="cell small-1 radio-button-cell">
//         <div className="radio-button-container relative">
//
//           <input
//             type="radio"
//             id={`question_${this.props.state.selectedQ + 1}_answer_${i + 1}`}
//             name={this.props.attributes[0]}
//             value={i + 1} // answer should be integer from 1-5
//             onChange={(e) => {
//               this.setState({otherVisible: false}, this.props.handleChange(e))
//             }}
//             className="absolute visually-hidden"
//           />
//
//           <label
//             htmlFor={`question_${
//               this.props.state.selectedQ + 1
//             }_answer_${i +1}`}
//             className="absolute visually-hidden top left"
//           >
//             {answer}
//           </label>
//
//           <div className="radio-button text-center"></div>
//         </div>
//       </div>
//       <div className="cell small-1 column align-center">
//         <div className="line hide-for-small-only"></div>
//       </div>
//     </>
//   ))}
// </div>
//
// <div className="checkboxes grid-x align-justify">
//   {this.props.answers.map((answer) => (
//     <div className="cell small-1  radio-button-cell">
//       <h4
//         className="vertical-text-for-small vertical-text-for-medium text-center"
//       >
//         {answer}
//       </h4>
//     </div>
//   ))}
// </div>
