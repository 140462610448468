import mapboxgl from 'mapbox-gl';


export const mapStyle = 'mapbox://styles/icecreamneil/ckeu6g6ev9f6i19qqlbm5pu59';

export const bounds = new mapboxgl.LngLatBounds(
  [-3.43845, 55.9226534],
  [-3.1722429, 56.0158208]
);

export const center = [-3.2797804997748017, 55.974896529739794];

const TOKEN = 'pk.eyJ1IjoiaWNlY3JlYW1uZWlsIiwiYSI6ImNqOTc5OTUzMTBiNzIycXF0bjB1bjFiOTYifQ.K2mrWaQZhVc7rLPLI5oAsw';


export const mapOptions = {
  // container: 'garnock-map-container',
  style: mapStyle,
  zoom: 14.3,
  center: center,
  pitch: 0,
  minZoom: 12.1,
  maxZoom: 20,
  maxBounds: bounds,
  bearing: 0,
  attributionControl: false, // attr control inserted below
}


export const setupMap = function(container, options = undefined){
  if(options) combine(mapOptions, options); // Merge new options if provided

  mapOptions.container = container;
  mapboxgl.accessToken = TOKEN;

  const map = new mapboxgl.Map(mapOptions);
  // Setup custom Attribution ctrl
  map.addControl(new mapboxgl.AttributionControl({compact:false}));
  return map;
}


// Combine objects, overriding old props with new
const combine = (orig, nu) => {
  Object.keys(orig).forEach(k => {
    if(nu.hasOwnProperty(k) && nu[k]) orig[k] = nu[k];
  })
}


export const addControls = (map, position = 'top-right') => {
  const ctrl = new mapboxgl.NavigationControl;
  map.addControl(ctrl, position);
}


export const addLine = (map, dataOrUrl, layerId) => {
  map.addSource(layerId, {
    "type": "geojson",
    "data": dataOrUrl
  })

  map.addLayer({
    "id": layerId,
    "type": "line",
    "source": layerId,
    "layout": {
      "line-cap": "round",
      "line-join": "round",
    },
    "paint": {
      "line-color": colors.pink,
      "line-width": 3,
    }
  }, 'places')
}



export const addSymbolLayer = (map, dataOrUrl, layerId) => {
  map.addSource(layerId, {
    "type": "geojson",
    "data": dataOrUrl
  });

  map.addLayer({
    "id": layerId,
    "type": "symbol",
    "source": layerId,
    "layout": {
      "icon-image": "{icon}",
      "icon-anchor": "bottom",
      "symbol-z-order": "viewport-y",
      "icon-size": 0.5
    }
  })

}


export const zoomToRoute = (map, route) => {
  const { coordinates } = route.features[0].geometry;

  setTimeout(
    () => map.easeTo({
      center: coordinates[0],
      zoom: 12.8,
      duration: 1000
    }),
    250
  );
}


export const setupGCBounds = (map) => {

  map.addSource('gc-bounds', {
    "type": "geojson",
    "data": '/gc-bounds.json'
  })

  map.addLayer({
    "id": "gc-bounds",
    "type": "line",
    "source": "gc-bounds",
    "layout": {
      "line-cap": "butt",
      "line-join": "miter",
      "line-miter-limit": 2,
    },
    "paint": {
      "line-color": "#3778fb",
      "line-width": 3,
      "line-dasharray": [6, 1],
    }
  }, 'places')
}


export const setupPopup = (map, layer) => {
  map.on('click', layer, (e) => {
    const { properties } = e.features[0];
    const { coordinates } = e.features[0].geometry

    // TAKEN from: https://docs.mapbox.com/mapbox-gl-js/example/popup-on-click/
    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    const html = createPointHTML(properties)

    // render HTML into Popup
    new mapboxgl.Popup({ offset: 15 })
      .setLngLat(coordinates)
      .setDOMContent(html)
      .addTo(map);
  })
}


const createPointHTML = (properties) => {
  const container = document.createElement('div');

  const title = document.createElement('h5');
  title.innerHTML = properties.title;

  container.appendChild(title);

  const buttonContainer = document.createElement('div');
  buttonContainer.classList.add('flex-container', 'align-center');

  const link = document.createElement('a');
  link.href = JsRoutesRails.trail_point_path({
    id: properties.id,
    trail_id: properties.trail_id,
  });

  const button = document.createElement('button');
  button.classList.add('button', 'expanded');
  button.innerHTML = 'View'

  // put button in link, then link in container
  link.appendChild(button);
  buttonContainer.appendChild(link);

  const editButton = document.createElement('button');
  editButton.classList.add('button', 'expanded');
  editButton.innerHTML = 'Edit';

  const editLink = document.createElement('a');
  editLink.classList.add('sml')
  editLink.href = JsRoutesRails.edit_trail_point_path({
    id: properties.id,
    trail_id: properties.trail_id,
  });

  // put button in link, then link in container
  editLink.appendChild(editButton);
  if(onEditTrailPage()) buttonContainer.appendChild(editLink);

  container.appendChild(buttonContainer);
  return container;
}


// Extract/Format Coordinates from obj
// Multiple conditions allow for 'click' and marker objects
export const getCoords = (object) => {
  const { lngLat, _lngLat } = object;
  if(lngLat) return [lngLat.lng, lngLat.lat];
  if(_lngLat) return [_lngLat.lng, _lngLat.lat];
}
