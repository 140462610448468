import React from "react"
import PropTypes from "prop-types"



class ConsultationTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.timelineContainer = React.createRef();
    this.scrollToEnd = this.scrollToEnd.bind(this);
    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
    this.years = [
      {
        label: "2019-2020",
        items: [
          {
            name: 'Community Consultation',
            link: false,
            onClick: () => {
              document.getElementById('2019-consultation').classList.remove('trans')
            }
          },
        ]
      },
      {
        label: "2021-2022",
        items: [
          {
            name: 'Maryhill North Neighbourhood Masterplan',
            link: true,
            url: this.props.masterPlanURL,
          },
          {
            name: 'Housing Environment Improvements',
            link: false,
            onClick: () => {
              document.getElementById('housing_environment_improvements').classList.remove('trans')
            }
          },
        ]
      },
      {
        label: "2023 - 2024",
        items: [
          {
            name: 'Proposed changes to the North Neighbourhood Masterplan',
            url: this.props.currentMasterPlanURL,
            link: true,
          },
          {
            name: 'Planning permission to be submitted (January 2024)',
            link: false,
          },

          {
            name: 'Consultation on proposed new housing',
            link: false,
          }
        ]
      },
    ]
  }

  componentDidMount() {
    // setTimeout(this.scrollToEnd, 2000)
    // var observer = new IntersectionObserver(function(entries) {
    // 	if(entries[0]['intersectionRatio'] === 1){
    //     this.scrollToEnd();
    //   }
    // });
    //
    // observer.observe(this.timelineContainer.current);
    //
    // observer.disconnect();
  }

  scrollToEnd() {
    this.timelineContainer.current.scrollTo({
      top: 0,
      left: this.timelineContainer.current.scrollWidth - this.timelineContainer.current.offsetWidth,
      behavior: 'smooth'
    })
  }

  scrollLeft() {
    this.timelineContainer.current.scrollTo({
      top: 0,
      left: this.timelineContainer.current.scrollLeft - (this.timelineContainer.current.scrollWidth * 0.25),
      behavior: 'smooth'
    })
  }

  scrollRight() {
    this.timelineContainer.current.scrollTo({
      top: 0,
      left: this.timelineContainer.current.scrollLeft + (this.timelineContainer.current.scrollWidth * 0.25),
      behavior: 'smooth'
    })
  }

  render() {

    const yearSections = this.years.map(year => {

      const itemElements = year.items.map((item) => {
        if (item.link) {
          return <a href={item.url}>
            <p className='bubble grow'>{item.name}</p>
          </a>
        } else if (item.onClick) {
          return <p className="bubble hand-on-hover primary bold grow" onClick={item.onClick}>{item.name}</p>
        } else {
          return <p className="bubble">{item.name}</p>
        }
      })

      const topItems = [];
      const bottomItems = [];

      itemElements.forEach((item, i) => {
        if (i % 2 == 0) {
          const bubbleContainer = <div className="bubble-container">
            {item}
            <div className="bubble-line" />
          </div>

          topItems.push(bubbleContainer)
        } else {
          const bubbleContainer = <div className="bubble-container">
            <div className="bubble-line" />
            {item}
          </div>

          bottomItems.push(bubbleContainer);
        }
      })

      return <div
        className="year-section cell small-12 medium-6 "
      >
        <div
          className="top-section"
        >
          {topItems}
        </div>

        <div
          className="timeline-section"
        >
          <h3>{year.label}</h3>
        </div>

        <div
          className="bottom-section"
        >
          {bottomItems}
        </div>
      </div>
    })

    return (
      <div
        id='timeline-section'
      >

        <h2 className="text-center">Consultation Timeline</h2>

        <div className='grid-x align-justify mmb'>
          <div className="cell small-1 column align-center small-order-2 medium-order-1">
            <button className='hand-on-hover' onClick={this.scrollLeft}>
              <i
                className="fa fa-chevron-left fa-2x"
                aria-hidden="true"
              />
            </button>
          </div>

          <div className="cell medium-9 large-8 small-order-1 medium-order-2">
            <p className='text-center'>
              Scroll along the timeline or use the arrow buttons on either side. Click on the
              <span className="primary-color bold"> purple </span>
              text to take part in live consultations or read about previous ones.
            </p>
          </div>

          <div className="cell small-1 column align-center small-order-3 medium-order-3">
            <button className='hand-on-hover' onClick={this.scrollRight}>
              <i
                className="fa fa-chevron-right fa-2x"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>


        <div
          ref={this.timelineContainer}
          id="timeline-container"
          className='grid-x'
          style={{ flexFlow: 'row' }}
        >
          {yearSections}
        </div>

      </div>
    );
  }
}

export default ConsultationTimeline
