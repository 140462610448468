import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons"

const Idea = (props) => {

  // add useState to style the background based upon whether voted for or not
  const [voted, setVoted] = useState(false)

  const {
    // voted,
    idea
  } = props;

  const {
    body,
    votes,
    id
  } = idea;

  return (
    <button
      className={`idea button expanded round-border mmb mp black-border ${voted ? 'green-bg voted' : 'hollow'}`}
      onClick={() => {
        const newVoteTally = voted ? votes - 1 : votes + 1;
        props.handleVote(
          id,
          newVoteTally,
          () => setVoted(!voted)
        )
      }}
    >

      <label className='nm h3'>{body}</label>

    </button>
  )
};

export default Idea;

Idea.propTypes = {
  handleVote: PropTypes.func,
  idea: PropTypes.object,
}
