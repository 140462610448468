
document.addEventListener('turbolinks:load', function(){

  const surveysFormPage = document.getElementById('survey-form');
  const visualSectionFormPage = document.getElementById('visual-section-form');

  if(surveysFormPage  || visualSectionFormPage){
    setItemNumber()

    $(document).on('cocoon:after-insert', function(e) {
      setItemNumber()
    })

    $(document).on('cocoon:after-remove', function(e) {
      setItemNumber()
    })

    $(document).on('ajax:success', function(e) {
      setItemNumber()
    })
  }

});


function setItemNumber(){
  const itemNumberElements = document.querySelectorAll('h4 span.item-number')

  const itemNumberElementsArray = Array.from(itemNumberElements)

  const visibleElements = itemNumberElementsArray.filter(el =>
    !!el.offsetParent)

  if (visibleElements.length > 0){
    visibleElements.forEach(function(element, index){
      element.innerText = index + 1;
    })
  }

}



