// Original version by Stephen Murdoch. Modified by Jim Bevington
// (Original taken from Link Up West Dunbartonshire)

import * as vis from './visibility';

import * as ajax from './ajax';
import { hideMediaFields, enableMediaButtons } from './addMediaItems';
import fileData from './fileValidationData';

import MyUploader from './MyUploader';


const FILE_TYPES = { v: 'video', i: 'image', a: 'audio', t: 'application' }

// HTML Templates for File Preview ////////////////////////////////////////////

const imageHTML = (index, id) => {
  return `
    <img id="image-preview-thumbnail-${index}" class="image-preview-thumbnail media-preview-thumbnail" data-signed-id="${id}" />
  `;
}


const videoHTML = (index, id) => {
  return `
    <video id="video-preview-thumbnail-${index}" class="video-preview-thumbnail media-preview-thumbnail" controls data-signed-id="${id}">
    </video>
   `;
}


const audioHTML = (index, id) => {
  return `
    <audio id="audio-preview-thumbnail-${index}" class="audio-preview-thumbnail media-preview-thumbnail border-radius" controls data-signed-id="${id}" />
   `;
}


const textHTML = (index, id) => {
  // NOTE - use 'application-preview-...' rather than document or text, as mime prefix is application
  return `
    <object data-signed-id="${id}">
      <embed id="application-preview-thumbnail-${index}" class='media-preview-thumbnail'/>
    </object>
   `;
}



const fileHTML = (file, index=0, id=null) => {
  const html = {
    image: imageHTML(index, id),
    video: videoHTML(index, id),
    audio: audioHTML(index, id),
    application: textHTML(index, id),
  }

  const type = getFileType(file);
  const li = document.createElement('li');
  li.classList.add('smb', 'relative', 'preview-thumbnail-container', `${type}-preview-container`)
  li.innerHTML = html[type];
  if(id) {
    const deleteButton = document.createElement('button');
    deleteButton.classList.add('button', 'absolute', 'top', 'right', 'no-border-radius', 'alert-bg')
    deleteButton.innerText = 'Delete';
    deleteButton.addEventListener('click', (e) => {
      e.preventDefault();
      deleteMedia(id)
    })
    li.appendChild(deleteButton) // add delete button if id given
  }

  const fileName = document.createElement('label');
  fileName.innerText = file.name;
  fileName.classList.add('h6');
  li.appendChild(fileName);

  return li;
}


const deleteMedia = (id) => {
  const url = '/media/' + id;
  ajax.deleteRequest(
    url,
    () => {
      // remove preview
      const preview = document.querySelector(`[data-signed-id="${id}"]`);
      preview.parentElement.remove();
      // remove attachment from form
      const field = document.querySelector(`input[value=${id}]`);
      field.remove();
    },
    () => alert('Failed to delete media')
  )
}


// Triggered when file attached to file field.
// Validate files:
// - show error if invalid
// - render previews if valid
const validateAndPreview = (e) => {


  // convert from FileList so we can iterate over it
  // const files = Array.from(e.target.files);
  const file = e.detail.file;
  let valid = validateFile(file); // in array or validator

  // render the preview and show valid message
  // in slider form we have two direct_uploads form
  // so get the index from that elements to render the image in the proper place
  const index = e.target.dataset.index

  if(valid){
    vis.hideBySelector(`.invalid-message[data-index="${index}"]`);
    // vis.hideById('no-media');


    if(multipleFilesForm(e.target)){
      // upload immediately
      uploadFileAndRenderPreview(file, e.target);
      e.target.value = null; // clear files from input
      refreshUI();
    } else {

      renderPreview(file, index);
      renderPreviewFile(file, index);
      vis.hideById('upload-guidelines');
      vis.showById('choose-image-button');
      // show valid message
      document.querySelector(`.valid-message[data-index="${index}"]`).innerHTML = renderValidMessage();
      vis.showBySelector(`.valid-message[data-index="${index}"]`);
    }

  } else {
    vis.hideBySelector(`.valid-message[data-index="${index}"]`);
    vis.showBySelector(`.invalid-message[data-index="${index}"]`);
  }
}




// Validate single or array of files
const validate = (files) => {
  if(typeof files === 'array') return validateFiles(files);
  else return validateFile(files);
}


// Validate single files
const validateFile = (file) => {
  const sizeValid = validateSize(file);
  const typeValid = validateType(file);
  return sizeValid && typeValid
}


// Validate a collection, i.e are all files valid?
const validateFiles = (files) => {
  let valid = true;

  for(let i = 0; i < files.length; i++){
    const file = files[i];
    if(!validateFile(file)){
      valid = false;
      break;
    }
  }

  return valid;
}


const uploadFileAndRenderPreview = (file, input) => {

  const url = input.dataset.directUploadUrl;

  const uploader = new MyUploader(file, url);

  vis.hideById('upload-error'); // ensure error is hidden
  vis.showById('progress-container');
  uploader.resetProgressBar();

  uploader.upload()
    .then(blob => {
      // Add an appropriately-named hidden input to the form with a
      //  value of blob.signed_id so that the blob ids will be
      //  transmitted in the normal upload flow
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("value", blob.signed_id);
      hiddenField.name = setMediaItemIndexInName(input.name);
      document.querySelector('form').appendChild(hiddenField);
      // Render the preview on the form
      renderPreviewsWithId([
        {file:file, id:blob.signed_id}
      ]);

      vis.hideById('progress-container');
    }, error => {
      // TODO - handle ERROR
      console.log(error);

      vis.showById('upload-error');

      vis.hideById('progress-container');
    });


}


// The same file input field is used to add images, audio, videos or documents
// Therefore, the name will always be item[media_items_attributes][0][<TYPE>]
// We need to increment the index - 0 - based upon number of existing
// media items and those that will be added, i.e elements in #previews and
// #media-items.
const setMediaItemIndexInName = (name) => {
  const index = getFileCount();
  return name.replace('0', index); // TODO add existing media_items
}



// Render a preview of each new file in #previews
// Doing this file by file reduces amount of elements that need rendering
const renderPreviewsWithId = (filesWithId) => {

  filesWithId.forEach((f, i) => {
    const { file, id } = f;
    const index = i + getFileCount();
    renderPreview(file, index, id);
    renderPreviewFile(file, index);
    // renderCaptionInput(getFileType(file), index); // DON'T NEED CAPTION
  })

  updateFileCount();
  renderAddMore();
}





const getFileType = (file) =>{
  for (let k in FILE_TYPES){
    let type = FILE_TYPES[k];
    if(file.type.startsWith(type))return type;
  }
}


const validateSize = (file) => {
  const type = getFileType(file);
  const { limit } = fileData[type]
  return file.size < limit;
}


const validateType = (file) => {
  const type = getFileType(file);
  const { types } = fileData[type]
  return types.includes(file.type);
}


// Render HTML for preview.
// NOTE - This is just the container, source is set in renderPreviewFile()
const renderPreview = (file, index=0, id=null) => {
  const previews = document.querySelectorAll('.previews');

  // if(!previews.classList.contains('multiple')) previews.innerHTML = '';
  previews[index].innerHTML = '';
  previews[index].appendChild(fileHTML(file, index, id))
}


// TODO - id in link
// Insert file from form into preview HTML element
const renderPreviewFile = (file, index=0) => {

  // Select HTML element to add file to
  const type = getFileType(file);
  var div = document.getElementById(`${type}-preview-thumbnail-${index}`);

  // Create callback to insert file when loaded
  let action;

  // IMAGE
  if(type === 'image'){
    action = () => { div.src = reader.result; }
  } else if(type === 'text') {
    // TEXT uses <embed> inside <object> requiring a data attr
    action = () => {
      div.src = reader.result;
      div.parentElement.data = reader.result;
      div.type = file.type;
    }
  } else {
    // VIDEO and VIDEO
    action = () => {
      div.src = reader.result;
      div.type = file.type;
    }
  }

  // Read file with FileReader and set onLoad callback (see above)
  if (div) {
    var reader = new FileReader();
    reader.onload = action;
    reader.readAsDataURL(file);
  }
}

// NOT USED IN Innovating Communities
const renderCaptionInput = (type, index) => {
  const input = document.createElement('input');
  input.setAttribute('type', 'text');
  input.setAttribute('placeholder', `Add a caption for this ${type === 'application' ? 'document' : type}`);
  input.classList.add('no-border', 'white-bg');
  input.setAttribute('name', `${getModelType()}[media_items_attributes][${index}][caption]`);
  input.id = `${getModelType()}_media_items_attributes_${index}_caption`;

  const previews = document.getElementById('previews');
  previews.appendChild(input);
  input.focus();
}


const getModelType = () => {
  const type = document.querySelector('body').classList[0];
  // Singularize if necessary, i.e 'items' => 'item'
  if(type[type.length - 1] === 's'){
    return type.slice(0, type.length - 1);
  } else {
    return type;
  }
}


const refreshUI = () => {
  hideMediaFields();
  enableMediaButtons();
}


// Calcuate total files from number of previews
const getFileCount = () => {
  const thumbs = document.querySelectorAll('.media-preview-thumbnail');
  return thumbs.length;
}


// Update UI to show number of files
const updateFileCount = () => {
  const fileCount = document.getElementById('file-count');
  if(fileCount) fileCount.innerHTML = getFileCount() + ' files selected';
}


const renderAddMore = () => {
  vis.showById('add-more-media-items');
}

const renderValidMessage = () => {
  return '<i class="fa fa-check success-color smr"></i> File is valid, and will upload when you submit this form';
}


const multipleFilesForm = (element) => {
  return element.classList.contains('media-items-field');
}


// Validate file when selected
addEventListener("direct-uploads:file-attached", validateAndPreview)
