export const toggle = (id) => {
  const button = document.getElementById(id);
  if(button) button.disabled = !button.disabled;
}

export const setById = (id, boolean=true) => {
  const button = document.getElementById(id);
  if(button) button.disabled = boolean;
}

export const set = (element, boolean=true) => {
  if(element) element.disabled = boolean;
}
