import mapboxgl from 'mapbox-gl';
import * as ajax from './ajax';
import * as mapData from './mapData';
import * as vis from './visibility';
import * as disable from './disable';
import * as scoopForm from './scoopForm';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import center from '@turf/center';
import tinycolor from 'tinycolor2';

let map;
let container;
let draw;

// const mapOptions = {
//   scrollZoom: false,
// }

// detect map and setup
const setupMap = () => {
  container = document.getElementById('map');
  if(container){
    map = mapData.setupMap('map');
    map.addControl(new mapboxgl.NavigationControl(), 'top-left');
    map.on('load', () => {
      setInteraction();
      loadData();
      loadDraw();
    })
    // map.on('click', printData) // DEV
  }
}


// Enable ScrollZooming with mouse wheel or when CTRL is clicked
// From https://github.com/mapbox/mapbox-gl-js/issues/6884#issuecomment-603230145
const setInteraction = () => {
  map.scrollZoom.disable();
  map.scrollZoom.setWheelZoomRate(0.02); // Default 1/450

  map.on("wheel", event => {
    if (event.originalEvent.ctrlKey) { // Check if CTRL key is pressed
      event.originalEvent.preventDefault(); // Prevent chrome/firefox default behavior
      if (!map.scrollZoom._enabled) map.scrollZoom.enable(); // Enable zoom only if it's disabled
    } else {
      if (map.scrollZoom._enabled) map.scrollZoom.disable(); // Disable zoom only if it's enabled
    }
  });

}


// Print Map Data - DEV
const printData = () => {
  console.log(map.getStyle());
  console.log(`Bearing: ${map.getBearing()}`);
  console.log(`Pitch: ${map.getPitch()}`);
  console.log(`Zoom: ${map.getZoom()}`);
  console.log(`Center: ${map.getCenter()}`);
}


const loadData = () => {
  setupMapAreas();
}


// If map-areas enabled, setup this layer
const setupMapAreas = () => {
  if(container.classList.contains('map-areas')) {
    addFillLayer('map-areas');
    setupMapAreaHover();
    map.on('click', 'map-areas', handleMapAreaClicked);
  }
}


const setupMapAreaHover = () => {

  // UNUSED POPUP OPTION
  // const namePopup = new mapboxgl.Popup({
  //   closeButton :   false,
  //   closeOnClick:   false,
  //   closeOnMove :   false,
  //   anchor      :   'bottom-left',
  //   className   :   'map-area-label',
  //   // offset: [0,3]
  // })
  //
  // const addPopup = (e) => {
  //   var coordinates = e.features[0].geometry.coordinates[0][0];
  //   var name = e.features[0].properties.name;
  //
  //   // // Set the popup background color using the color property.
  //   // // Tiny color sets the alpha property for opacity.
  //   // var areaColor = e.features[0].properties.color;
  //   // let bgColor = tinycolor(areaColor);
  //   // bgColor.setAlpha(0.5);
  //   // const colorString = bgColor.toRgbString();
  //
  //   // Ensure that if the map is zoomed out such that multiple
  //   // copies of the feature are visible, the popup appears
  //   // over the copy being pointed to.
  //   while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
  //     coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
  //   }
  //
  //   namePopup.setLngLat(coordinates).setHTML(`<label className='map-area-label-popup ignore-click' '>${name}</label>`).addTo(map);
  // }

  // map.on('mouseenter', 'map-areas', addPopup);
  // map.on('mouseleave', 'map-areas', () => {
  //   namePopup.remove();
  // });


  // ALTERNATIVE CUSTOM POPUP

  const mouseLabel = document.getElementById('mouse-label')

  if(mouseLabel){
    map.on('mouseenter', 'map-areas', (e) => {
      var name = e.features[0].properties.name;

      // Add Name to label
      document.getElementById('label-name').innerText = name

      // If there is popup with this name id, show read-more-label
      const popupId = name.toLowerCase().replace(/ /g,"-");
      if(document.getElementById(popupId)){
        vis.showById('read-more-label');
      }

      mouseLabel.style.left = e.originalEvent.pageX + 'px';
      mouseLabel.style.top = e.originalEvent.pageY + 'px';
      vis.show(mouseLabel);

      map.getCanvas().style.cursor = 'pointer'
    })

    map.on('mouseleave', 'map-areas', () => {
      vis.hide(mouseLabel);
      vis.hideById('read-more-label');
      map.getCanvas().style.cursor = ''
    })
  }

}




const handleMapAreaClicked = (e) => {
  // Convert name into kebab-case to match #id
  const name = e.features[0].properties.name;
  const id = name.toLowerCase().replace(/ /g,"-");

  if(id){
    // find popup
    const innerPopup = document.getElementById('inner-popup');
    if(innerPopup){
      // hide all elements except close button
      [...innerPopup.children].forEach(el => {
        if(el.id !== 'close-popup') el.classList.add('hidden')
      });
      // reveal markup for clicked MapArea
      const mapAreaMarkup = document.getElementById(id);
      if (mapAreaMarkup) {
        mapAreaMarkup.classList.remove('hidden');
        // show popup
        document.getElementById('map-area-popup').classList.remove('trans');
      }
    }
  }
}


const refreshData = (klass) => {
  const url = '/' + klass + '.json';
  ajax.getJSON(url, (res) => { map.getSource(klass).setData(res) });
}


const loadDraw = () => {
  if(container.classList.contains('editable')){
    draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      }
      // styles: drawStyles
    });

    map.addControl(draw, 'top-left');
    toggleDrawControls(); // hide initially
    if(canAdd('map-area') || canEdit('map-area')) configureDraw();
    if(canAdd('map-area')) loadAddMapArea();
    if(canEdit('map-area')) loadEditMap();
  }
}


// EDIT MAP AREA //////////////////////////////////////////////////////////////

const canEdit = () => {
  return container.getAttribute('data-edit') === 'map-area';
}


const loadEditMap = () => {
  const editButton = document.getElementById('edit-button');
  if (editButton) editButton.addEventListener('click', handleEditClicked);
}


const anyFeatures = (sourceId) => {
  const features = map.querySourceFeatures(sourceId);
  return features && features.length > 0;
}


const handleEditClicked = () => {
  if(anyFeatures('map-areas')){

    // disable default behaviour
    map.off('click', 'map-areas', handleMapAreaClicked);

    // disable edit and add buttons
    disable.setById('new-button');
    disable.setById('edit-button');
    // show edit message
    setMessage(true, 'Click the area you want to Edit', true);

    // - remove click listener from map-areas
    map.off('click', 'map-areas', handleMapAreaClicked);

    // - add new click listener to map-areas
    map.on('click', 'map-areas', handleEditableAreaClicked);
  } else {
    setMessage(true, 'No areas to edit!', true);
  }
}


const setMessage = (visible, text, flash=false) => {
  const message = document.getElementById('message');
  if(!visible) message.classList.add('trans');
  else {
    message.innerText = text;
    message.classList.remove('trans');
    if(flash){
      // Fade out after 5s
      setTimeout(
        () => message.classList.add('trans'),
        2000
      )
    }
  }
}


const handleEditableAreaClicked = (e) => {
  // Remove this listener to prevent multiple areas being selected
  map.off('click', 'map-areas', handleEditableAreaClicked);


  // DRAW controls
  toggleDrawControls() // show draw/Trash control
  disable.set(getDrawButton()) // disable Draw button
  disable.set(getTrashButton(), false) // enable Trash button


  setMessage(true, 'Now click it again to activate it. Then, drag to move it, or click again to show breakpoints for changing shape.\n\nIf you just want to rename it or change the colour, click Save and use the popup form.', true);

  // Render Map Area for editing
  const area = e.features[0];
  draw.add(area);
  // select area // HOW
  // change edit message // TODO
  // Setup buttons
  addButtonListeners();
  // Enable and show Save btton
  disable.setById('save-button', false)
  vis.showById('save-cancel-button-container') // show Save/Cancel buttons

  // Activate Delete button
  const deleteButton = document.getElementById('delete-button');
  vis.show(deleteButton);
  deleteButton.addEventListener('click', handleDeleteClicked);
}


const handleDeleteClicked = () => {
  if(confirm('Are you sure you want to delete this?')){
    const id = draw.getAll().features[0].properties.id;
    const url = container.getAttribute('data-url') + '/' + id;
    ajax.deleteRequest(
      url,
      // onSuccess
      () => {
        refreshData('map-areas');
        setMessage(true, 'Deleted!', true);
        handleCancelClicked(); // reset everything
      },
      // onError: Flash error message
      (message) => setMessage(true, message, true)
    )
  }
}


// ADD DRAW FEATURES //////////////////////////////////////////////////////////

const canAdd = (type) => {
  return container.getAttribute('data-add') === type;
}

const toggleDrawControls = () => {
  if(getDrawButton()) vis.toggle(getDrawButton().parentElement);
}


const resetDraw = () => {
  draw.deleteAll(); // clear drawing board
  disable.set(getDrawButton, false) // enable draw button
  // hide controls
  if(getDrawButton()) vis.hide(getDrawButton().parentElement);
  vis.hideById('save-cancel-button-container') // hide save/cancel buttons
  disable.setById('save-button', true) // disable save button (nothing to save)
  disable.setById('new-button', false) // enable new button
  disable.setById('edit-button', false) // enable new button
}


const loadAddMapArea = () => {
  // Setup UI
  const newButton = document.getElementById('new-button');
  if(newButton) newButton.addEventListener('click', handleAddClicked);
}


const configureDraw = () => {
  map.on('draw.create', (e) => {
    disable.set(getDrawButton(), true) // disable draw button
    disable.setById('save-button', false) // enable save button
  })

  map.on('draw.delete', () => {
    disable.set(getDrawButton(), false) // enable draw button
    disable.setById('save-button', true) // disable save button
  })
}


// Add New button on main page
// - show the draw controls
// - disable Add new button
// - disable Save (nothing to save)
// - show save/cancel buttons
const handleAddClicked = () => {
  // disable default behaviour
  map.off('click', 'map-areas', handleMapAreaClicked);

  setMessage(true, 'Click the Draw button in the top-left to start drawing shapes.', true);

  // DRAW controls
  toggleDrawControls() // show draw control
  disable.set(getDrawButton(), false) // enable Draw button

  addButtonListeners();

  disable.setById('new-button', true) // disable Add New button
  disable.setById('edit-button', true) // disable Edit button
  disable.setById('save-button', true) // disable Save button (nothing to save)
  vis.showById('save-cancel-button-container') // show Save/Cancel buttons
}


// Attach Listeners to SAVE, CANCEL & FORM CONTROLS
const addButtonListeners = () => {
  const saveButton = document.getElementById('save-button');
  if(saveButton) saveButton.addEventListener('click', handleSaveClicked)

  // Cancel button on page and on form have same effect.
  const cancelButton = document.getElementById('cancel-button');
  if(cancelButton) cancelButton.addEventListener('click', handleCancelClicked)
  const cancelFormButton = document.getElementById('clear-form');
  if(cancelFormButton) cancelFormButton.addEventListener('click', handleCancelClicked)

  const submitButton = document.querySelector('.override-submit');
  if(submitButton) submitButton.addEventListener('click', handleFormSubmission)
}


// Clear Listeners
const removeButtonListeners = () => {
  const saveButton = document.getElementById('save-button');
  if(saveButton) saveButton.removeEventListener('click', handleSaveClicked)

  const cancelButton = document.getElementById('cancel-button');
  if(cancelButton) cancelButton.removeEventListener('click', handleCancelClicked)

  const submitButton = document.querySelector('.override-submit');
  if(submitButton) submitButton.removeEventListener('click', handleFormSubmission)
}


// Save button on main page
// - Insert data into form
// - open form popup
const handleSaveClicked = () => {
  // There should be only 1 feature in Draw at a time. Get it.
  const area = draw.getAll().features[0]

  // Setup form for editing, if ID present
  if(area.properties.id) {
    const form = document.querySelector('form');
    scoopForm.configureFormForEditing(form, area.properties.id);
    scoopForm.populateFormFromObject(form, area.properties, 'map-area')
  }

  // Add Geojson to form from area Feature
  const geojsonField = document.getElementById('map_area_geojson');
  geojsonField.value = JSON.stringify(area);

  // Open form
  const popup = document.getElementById('form-popup');
  popup.classList.remove('trans')
}


// Cancel Button on main page
// - clear Draw features
// - clear form
// - enable Add New button
// - hide save/cancel buttons
// - hide the Draw controls
const handleCancelClicked = () => {
  // enable default behaviour
  map.on('click', 'map-areas', handleMapAreaClicked);
  draw.deleteAll();
  resetForm();
  disable.set(getDrawButton(), false) // enable draw button
  disable.setById('new-button', false) // enable add new button
  disable.setById('edit-button', false) // enable add new button
  vis.hideById('save-cancel-button-container') // hide save/cancel buttons
  closeFormIfOpen()
  removeButtonListeners();
  // Hide Draw
  if(getDrawButton()) vis.hide(getDrawButton().parentElement);
}


// Submit MapArea via AJAX
const handleFormSubmission = (e) => {
  e.preventDefault();

  let url = container.getAttribute('data-url');
  let method = 'POST';
  const form = document.querySelector('form');

  // attach id to url if its present in form
  if(form.elements.namedItem('map_area_id')){
    url += `/${form.elements.namedItem('map_area_id').value}`;
    method = 'PATCH';
  }

  const data = new FormData(form);

  ajax.sendData(
    data,
    url,
    (res) => {
      if(res.success){
        resetForm();
        toggleFormPopup(); // close form popup
        resetDraw();
        refreshData('map-areas');
        // enable default behaviour
        map.on('click', 'map-areas', handleMapAreaClicked);
      } else {
        renderErrors(res.error)
      }
    },
    renderErrors,
    method
  )
}


const closeFormIfOpen = () => {
  const popup = document.getElementById('form-popup');
  if(!popup.classList.contains('trans')) toggleFormPopup();
}


// Toggle the form & clear Errors
const toggleFormPopup = () => {
  clearFormErrors();
  const popup = document.getElementById('form-popup');
  popup.classList.toggle('trans')
}


const clearFormErrors = () => {
  const errors = document.getElementById('errors');
  errors.innerHTML = ''; // reset
}


const getDrawButton = () => {
  return document.querySelector('.mapbox-gl-draw_ctrl-draw-btn');
}

const getTrashButton = () => {
  return document.querySelector('mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash');
}


const renderErrors = (errors=[]) => {
  clearFormErrors();

  const errorsList = document.getElementById('errors')
  errors.forEach(e => {
    const li = document.createElement('LI');
    // li.classList.add('bold')
    const text = document.createTextNode(e);
    li.appendChild(text);
    errorsList.appendChild(li);
  })

  errorsList.parentElement.classList.remove('hidden');
}


// Loop through form elements and reset all those we need.
// Avoid auth token and submit buttonr
const resetForm = () => {
  const form = document.querySelector('form');
  scoopForm.resetInputFields(form);
  scoopForm.removeMethodFields(form);
  scoopForm.removeIdFromForm(form, 'map_area');
}


// TODO - detect if edit, make mapAreas listen for clicks, activate conrols

// MAPBOX LAYER ////////////////////////////////////////////////////////////////

const addFillLayer = (layer, url=undefined, beforeId=undefined) => {
  map.addSource(layer, {
    "type": "geojson",
    "data": `/${url ? url : layer}` // use layer for url if none provided
  });

  // Add FILL layer
  map.addLayer({
    "id": `${layer}`,
    "type": "fill",
    "source": `${layer}`,
    "layout": {},
    "paint": {
      "fill-color": ["get", "color"],
      "fill-opacity": 0.2,
    }
  })

  // Add Line layer
  map.addLayer({
    "id": `${layer}-outline`,
    "type": "line",
    "source": `${layer}`,
    "layout": {},
    "paint": {
      "line-color": ["get", "color"],
      "line-width": 2
    }
  })

}


// const setCursorHoverStyle = (layer) => {
//   const pointerCursor = () => { map.getCanvas().style.cursor = 'pointer' };
//   const stdCursor = () => { map.getCanvas().style.cursor = '' };
//   map.on('mouseenter', layer, pointerCursor);
//   map.on('mouseleave', layer, stdCursor);
// }


document.addEventListener('turbolinks:load', setupMap);
