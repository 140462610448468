import Rails from '@rails/ujs';

document.addEventListener('turbolinks:load', function(){
  const sectionFormPage = document.querySelector('.sections form')

  if (sectionFormPage){

    // if on edit page hide all added elements, and add event listeners to edit button
    hideNestedSectionsForms();
    // add click event listeners to all added elements
    addClickEventListenersForAddedElementsEditButton()


    $(document).on('cocoon:after-insert', function(e) {
      // disable add buttons for visual section, slider and slideshow
      disableAddSectionLinks()

      // add click listeners to main sections only .remove-section-links
      $('.remove-section-links').on('click',function(e){
        $("a.visual-section.add_fields").attr('disabled', false);
        $("a.add_slider.add_fields").attr('disabled', false);
        $("a.slideshow.add_fields").attr('disabled', false);
      })

      // allow only one 'add key' button per image or slider
      if (e.target.classList.contains('add-key-link')){
        e.target.lastElementChild.classList.add('hidden')
      }

      // When adding new slide, it's image fields should be shown automatically
      // we can do this but triggering click event on 'add-image' link
      if (e.target.classList.contains('add-slide-link')){
        const addImageBtn = e.target.previousElementSibling.querySelector('#image .links a')

        if (addImageBtn) addImageBtn.click()
      }
    })

    $(document).on('cocoon:before-remove', function(e) {
      // check which element is about to removed
      // add confirmation to main ones only
      if(e.target.id === 'sliders' || e.target.id === 'visual_sections' || e.target.id === 'slideshows'){
        const confirmation = confirm("Are you sure you want to delete this element?");

        if( confirmation === false ){
          e.preventDefault();
        }
      }
    })


    $(document).on('cocoon:after-remove', function(e) {
      // check what field was removed

      // if key then get first child of last element and make it visible
      if (e.target.id === 'key'){
        e.target.lastElementChild.children[0].classList.remove('hidden')
      }

      // if one of main elements -> evoke form submission to update list of added elements
      if(e.target.id === 'sliders' || e.target.id === 'visual_sections' || e.target.id === 'slideshows'){
        submitForm();
      }
    })



    $(document).on('ajax:success', function(e){
      const [data] = e.detail;

      // hide nested form if form has no errors
      if (!data.includes('wrong with this form')){
        hideNestedSectionsForms();
      }

      // add click event listeners to all added elements
      addClickEventListenersForAddedElementsEditButton()
    })

  }
});

function hideNestedSectionsForms(){
  // hide only direct child of main section form
  $('#visual_sections > .visual-section-nested-fields').hide()
  $('#slideshows > .slideshow-nested-fields').hide()
  $('#sliders > .slider-nested-fields').hide()
}


function disableAddSectionLinks(){
  $("a.visual-section.add_fields").attr('disabled', true);
  $("a.add_slider.add_fields").attr('disabled', true);
  $("a.slideshow.add_fields").attr('disabled', true);;


  $('.add-section-links').on('click', 'a[disabled]', function(e){
    e.preventDefault();
    return false
  })
}


function submitForm(){
  const form =  document.querySelectorAll('form[data-remote]')[0]
  Rails.fire(form, 'submit');
}

function addClickEventListenersForAddedElementsEditButton(){
  $('.edit-section-button').on('click', function(e){
    // 'clear' all forms
    hideNestedSectionsForms();

    disableAddSectionLinks();

    // get elements where input value equals sectionID
    // loop through them to get previous Element Sibling
    // which should be div with .nested-elements class and
    // has data-set with its type
    // we can check if that data type is same as from edit button
    // if so, show this element

    const sectionID = e.target.dataset.id;
    const sectionType = e.target.dataset.type;

    const hiddenInputsWithId = document.querySelectorAll(`input[value="${sectionID}"]`); // as we can have i.e visual_section, slideshow and slider with same id

    hiddenInputsWithId.forEach(element => {
      const elementSibling = element.previousElementSibling;
      if(elementSibling.dataset.type === sectionType){
        elementSibling.style.display = 'block';
      }
    })
  })
}
